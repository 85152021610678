import React, { useState, useEffect } from 'react';
import { AppImages } from '../../services/AppImages';
import { Link, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { AppLogger } from '../../services/AppLogger';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Navigation from "../../components/navbar/Navigation";
import Sidebar from '../../components/sidebar/Sidebar';
import UnitDataService from "../../services/unit.service";
import FBServices from "../../services/unit.services";
import AppRoutes from '../../services/AppRoutes';
import {
    Container,
    Paper,
    Box,
    Typography,
    CircularProgress,
    Avatar,
    Chip,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    InputAdornment,
    Button,
    IconButton
} from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SortIcon from '@mui/icons-material/Sort';

// Custom CSS styles
import "./HashtagUsersList.css";

function HashtagUsersList() {
    const [usersList, setUsersList] = useState([]);
    const [allPosts, setAllPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'postCount', direction: 'desc' });
    const history = useHistory();
    const hashtagName = "#outdoortrader";

    useEffect(() => {
        getAllPostsWithHashtag();
    }, []);

    // Get all posts with #outdoortrader hashtag
    const getAllPostsWithHashtag = async () => {
        setLoading(true);
        try {
            const data = await UnitDataService.getAllPostsFrFirebase();
            // Filter posts containing #outdoortrader in the caption
            const outdoorPosts = data.docs
                .map((doc) => ({ ...doc.data(), id: doc.id }))
                .filter((post) => post.caption && post.caption.toLowerCase().includes(hashtagName.toLowerCase()));

            setAllPosts(outdoorPosts);
            processUserData(outdoorPosts);
        } catch (error) {
            console.error("Error fetching posts:", error);
            setLoading(false);
        }
    };

    // Process user data to extract unique users with hashtags and count their posts
    const processUserData = async (posts) => {
        setIsProcessing(true);
        try {
            // Group posts by user ID and count them
            const userPostsMap = {};

            posts.forEach(post => {
                const userId = post.createdBy;
                if (!userId) return;

                if (!userPostsMap[userId]) {
                    userPostsMap[userId] = {
                        userId: userId,
                        posts: [],
                        postCount: 0
                    };
                }

                userPostsMap[userId].posts.push(post);
                userPostsMap[userId].postCount += 1;
            });

            // Fetch user details for each user
            const usersData = [];
            let serialNumber = 1;

            // Convert userPostsMap to array and sort by post count descending
            const sortedUserIds = Object.keys(userPostsMap).sort((a, b) =>
                userPostsMap[b].postCount - userPostsMap[a].postCount
            );

            for (const userId of sortedUserIds) {
                try {
                    const userDetails = await FBServices.getUserDetails(userId);

                    if (userDetails.docs && userDetails.docs.length > 0) {
                        const userData = userDetails.docs[0].data();

                        usersData.push({
                            ...userPostsMap[userId],
                            fullName: userData.fullName || "Unknown User",
                            email: userData.userEmail || "",
                            profilePicture: userData.profilePicture || AppImages.placeholder,
                            createdAt: userData.createdAt || "",
                            serialNumber: serialNumber++
                        });
                    } else {
                        usersData.push({
                            ...userPostsMap[userId],
                            fullName: "Unknown User",
                            email: "",
                            profilePicture: AppImages.placeholder,
                            createdAt: "",
                            serialNumber: serialNumber++
                        });
                    }
                } catch (error) {
                    console.error(`Error fetching details for user ${userId}:`, error);
                    usersData.push({
                        ...userPostsMap[userId],
                        fullName: "Unknown User",
                        email: "",
                        profilePicture: AppImages.placeholder,
                        createdAt: "",
                        serialNumber: serialNumber++
                    });
                }
            }

            setUsersList(usersData);
        } catch (error) {
            console.error("Error processing user data:", error);
        } finally {
            setLoading(false);
            setIsProcessing(false);
        }
    };

    // Handle sorting
    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedData = [...usersList].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        setUsersList(sortedData);
    };

    // Handle searching
    const filteredUsers = usersList.filter(user =>
        user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Handle view posts
    const handleViewPosts = (userId) => {
        // Store the selected user ID in localStorage
        localStorage.setItem('selectedHashtagUser', userId);
        // Navigate to the hashtag posts page
        history.push(AppRoutes.listHashtagPosts);
    };

    return (
        <>
            <div className='side-wrp'>
                <Sidebar />
            </div>

            {/* Content Area */}
            <div className="content-area" style={{ marginLeft: '400px', padding: '20px', backgroundColor: '#f5f5f5' }}>
                <Container maxWidth="lg" style={{ padding: '20px 0 20px 20px', maxWidth: '100%', backgroundColor: '#f5f5f5' }}>
                    <Link
                        to={AppRoutes.home}
                        className="back-button"
                        style={{
                            textDecoration: 'none',
                            display: 'inline-flex',
                            alignItems: 'center',
                            padding: '8px 16px',
                            borderRadius: '20px',
                            color: '#333',
                            fontWeight: 'bold',
                            transition: 'all 0.3s',
                            margin: '15px 0',
                            backgroundColor: '#f0f0f0',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                    >
                        <ArrowBackIcon sx={{ mr: 1 }} /> Back to Home
                    </Link>

                    {/* Header */}
                    <Paper sx={{
                        borderRadius: '8px',
                        overflow: 'hidden',
                        mb: 3
                    }}>
                        <Box sx={{
                            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                            color: 'white',
                            p: 4,
                            textAlign: 'center'
                        }}>
                            <Typography variant="h4" fontWeight="bold" gutterBottom>
                                Users with Hashtag
                            </Typography>
                            <Chip
                                icon={<TagIcon />}
                                label="outdoortrader"
                                sx={{
                                    bgcolor: 'rgba(255, 255, 255, 0.2)',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '1.1rem',
                                    px: 1,
                                    py: 2,
                                    '& .MuiChip-icon': {
                                        color: 'white'
                                    }
                                }}
                            />
                        </Box>
                    </Paper>

                    {/* Search Bar */}
                    <Paper sx={{ p: 2, mb: 3 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search by user name or email..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Paper>

                    {/* Users Table */}
                    {(loading || isProcessing) ? (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '300px',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                        }}>
                            <CircularProgress color="primary" size={40} />
                            <Typography variant="h6" sx={{ ml: 2 }}>
                                Loading users data...
                            </Typography>
                        </Box>
                    ) : filteredUsers.length > 0 ? (
                        <TableContainer component={Paper} sx={{ borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.1)' }}>
                            <Table>
                                <TableHead sx={{ backgroundColor: '#f5f9ff' }}>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', width: '80px' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleSort('serialNumber')}>
                                                S.No
                                                <IconButton size="small">
                                                    <SortIcon fontSize="small" color={sortConfig.key === 'serialNumber' ? 'primary' : 'action'} />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleSort('fullName')}>
                                                User
                                                <IconButton size="small">
                                                    <SortIcon fontSize="small" color={sortConfig.key === 'fullName' ? 'primary' : 'action'} />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleSort('email')}>
                                                Email
                                                <IconButton size="small">
                                                    <SortIcon fontSize="small" color={sortConfig.key === 'email' ? 'primary' : 'action'} />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => handleSort('postCount')}>
                                                Posts Count
                                                <IconButton size="small">
                                                    <SortIcon fontSize="small" color={sortConfig.key === 'postCount' ? 'primary' : 'action'} />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredUsers.map((user) => (
                                        <TableRow key={user.userId} hover>
                                            <TableCell>
                                                <Chip
                                                    label={user.serialNumber}
                                                    color="primary"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        minWidth: '40px',
                                                        background: user.serialNumber <= 3 ? 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)' : undefined
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Avatar src={user.profilePicture} alt={user.fullName} sx={{ width: 50, height: 50, mr: 2, border: '2px solid #f0f0f0' }} />
                                                    <Typography variant="body1" fontWeight="medium">{user.fullName}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell align="center">
                                                <Chip
                                                    label={user.postCount}
                                                    color="primary"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        minWidth: '60px',
                                                        background: user.postCount > 5 ? 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)' : undefined
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    startIcon={<VisibilityIcon />}
                                                    onClick={() => handleViewPosts(user.userId)}
                                                >
                                                    View Posts
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Box sx={{
                            textAlign: 'center',
                            padding: '60px 0',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                        }}>
                            <TagIcon sx={{ fontSize: 60, color: '#cccccc', mb: 2 }} />
                            <Typography variant="h5" gutterBottom>
                                No Users Found
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                No users with {hashtagName} hashtag were found.
                            </Typography>
                        </Box>
                    )}
                </Container>
            </div>
        </>
    );
}

export default HashtagUsersList;