import React, { useState, useEffect } from 'react'
import { AppImages } from '../../services/AppImages';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { AppLogger } from '../../services/AppLogger';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Navigation from "../../components/navbar/Navigation"
import Sidebar from '../../components/sidebar/Sidebar'
import PostItem from '../../components/post/PostItem';
import UnitDataService from "../../services/unit.service"
import FBServices from "../../services/unit.services"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../../App.css"
import AppRoutes from '../../services/AppRoutes';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DateFormats, handleDateTime } from '../../services/AppConstant';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import TagIcon from '@mui/icons-material/Tag';
import { Container, Paper, Avatar, Divider, Button, IconButton } from '@mui/material';
import GalleryModal from '../../components/GalleryModal';
import LikesModal from '../../components/LikesModal';
import CommentsModal from '../../components/CommentsModal';
import DeleteCommentModal from '../../components/DeleteCommentModal';
import ReportedUsersModal from '../../components/ReportedUsersModal';
import CustomModal from '../../components/CustomModal';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Custom CSS styles
import "./ListOfHashtagPosts.css";

const styles = {
    header: {
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        color: 'white',
        padding: '30px 0',
        borderRadius: '8px',
        marginBottom: '20px',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
    },
    backButton: {
        textDecoration: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        padding: '8px 16px',
        borderRadius: '20px',
        color: '#333',
        fontWeight: 'bold',
        transition: 'all 0.3s',
        margin: '15px 0',
        backgroundColor: '#f0f0f0',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        border: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        },
    },
    container: {
        padding: '20px 0 20px 20px',
        maxWidth: '100%',
        backgroundColor: '#f5f5f5',
    },
    postContainer: {
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        marginBottom: '24px',
        backgroundColor: 'white',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)',
        },
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
    },
    actionButton: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 10px',
        cursor: 'pointer',
        color: '#777',
        transition: 'color 0.2s',
        '&:hover': {
            color: '#2196F3',
        },
    },
    actionButtonActive: {
        color: '#2196F3',
    },
};

function ListOfHashtagPosts() {
    const [listOfPosts, setlistOfPosts] = useState([])
    const [filteredPosts, setFilteredPosts] = useState([])
    const [AllUsers, setAllUsers] = useState([])
    const [loading, setLoading] = useState(true)
    const [isProcessing, setIsProcessing] = useState(false)
    const [showGallery, setShowGallery] = useState(false)
    const [selectedGalleryItem, setSelectedGalleryItem] = useState(0)
    const [selectedPost, setSelectedPost] = useState(null)
    const hashtagName = "#outdoortrader"

    // Modals state
    const [showLikes, setShowLikes] = useState(false)
    const [showComments, setShowComments] = useState(false)
    const [showReportedUsers, setShowReportedUsers] = useState(false)
    const [showPostDelete, setShowPostDelete] = useState(false)
    const [currentPostId, setCurrentPostId] = useState(null)
    const [currentLikesArray, setCurrentLikesArray] = useState([])
    const [currentCommentsArray, setCurrentCommentsArray] = useState([])
    const [currentReportedUsers, setCurrentReportedUsers] = useState([])
    const [selectedUserId, setSelectedUserId] = useState(null);

    const [deleteModal, setDeleteModal] = useState({
        type: "",
        commentIndex: 0,
        replyIndex: 0,
        show: false,
    })

    useEffect(() => {
        // Check if a user ID is stored in localStorage
        const storedUserId = localStorage.getItem('selectedHashtagUser');
        if (storedUserId) {
            setSelectedUserId(storedUserId);
            // Clear it after retrieving
            localStorage.removeItem('selectedHashtagUser');
        }
        getOutdoorHashtagPosts();
        getAllUsers()
    }, [])

    // This is a new method to get only posts with #outdoortrader hashtag
    const getOutdoorHashtagPosts = async () => {
        setLoading(true);
        try {
            const data = await UnitDataService.getAllPostsFrFirebase();
            // Filter posts containing #outdoortrader in the caption
            const outdoorPosts = data.docs
                .map((doc) => ({ ...doc.data(), id: doc.id }))
                .filter((post) => post.caption && post.caption.toLowerCase().includes(hashtagName.toLowerCase()));

            setlistOfPosts(outdoorPosts);
            setIsProcessing(true); // Indicate we're now processing user details
        } catch (error) {
            console.error("Error fetching posts:", error);
            setLoading(false);
            setIsProcessing(false);
        }
    };

    const getAllUsers = async () => {
        const data = await UnitDataService.getAllUnit();
        setAllUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    const handleUserDetails = () => {
        const finalArray = [];

        // Create a counter to track processed items
        let processedItems = 0;

        // Filter posts by selected user if needed
        const postsToProcess = selectedUserId
            ? listOfPosts.filter(post => post.createdBy === selectedUserId)
            : listOfPosts;

        const totalItems = postsToProcess.length;

        if (totalItems === 0) {
            setFilteredPosts([]);
            setLoading(false);
            setIsProcessing(false);
            return;
        }

        postsToProcess.forEach(async (element) => {
            try {
                const userDetails = await FBServices.getUserDetails(element.createdBy);

                if (userDetails.docs && userDetails.docs.length > 0) {
                    const userData = userDetails.docs[0].data();

                    // Process likes array
                    const likesArray = await processLikesArray(element.likes || []);

                    // Process comments array
                    const commentsArray = await processCommentsArray(element.comments || []);

                    finalArray.push({
                        ...element,
                        userDetails: userData,
                        fullName: userData.fullName || "Unknown User",
                        profilePicture: userData.profilePicture || AppImages.placeholder,
                        processedLikes: likesArray,
                        processedComments: commentsArray
                    });
                } else {
                    // Handle case where user details aren't found
                    finalArray.push({
                        ...element,
                        userDetails: {},
                        fullName: "Unknown User",
                        profilePicture: AppImages.placeholder,
                        processedLikes: [],
                        processedComments: []
                    });
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
                // Still add the post even if user details fail
                finalArray.push({
                    ...element,
                    userDetails: {},
                    fullName: "Unknown User",
                    profilePicture: AppImages.placeholder,
                    processedLikes: [],
                    processedComments: []
                });
            }

            // Increment the counter
            processedItems++;

            // Update state when all items are processed
            if (processedItems === totalItems) {
                setFilteredPosts([...finalArray]);
                setLoading(false);
                setIsProcessing(false);
            }
        });
    }

    // Process likes array similar to handleUserDetailsForLikes in PostItem
    const processLikesArray = async (likesArray) => {
        if (!Array.isArray(likesArray)) return [];

        const finalArray = [];

        for (const element of likesArray) {
            const getUser = AllUsers.find((usrItem) => get(usrItem, "id", "") === get(element, "likedBy", ""));
            finalArray.push({
                ...element,
                profilePicture: get(getUser, "profilePicture", ""),
                fullName: get(getUser, "fullName", "")
            });
        }

        return finalArray;
    };

    // Process comments array similar to handleUsersDetailsForComments in PostItem
    const processCommentsArray = async (commentsArray) => {
        if (!Array.isArray(commentsArray)) return [];

        const comments = [];

        for (const item of commentsArray) {
            const repliesArr = [];
            const userDetails = AllUsers.find((usrItem) => get(usrItem, "id", "") === get(item, "commentBy", ""));

            if (Array.isArray(item.replies)) {
                for (const replyItem of item.replies) {
                    const getUser = AllUsers.find((usrItem) => get(usrItem, "id", "") === get(replyItem, "replyBy", ""));
                    repliesArr.push({
                        ...replyItem,
                        profilePicture: get(getUser, "profilePicture", ""),
                        fullName: get(getUser, "fullName", "")
                    });
                }
            }

            comments.push({
                ...item,
                replies: repliesArr,
                showAllReplies: false,
                profilePicture: get(userDetails, "profilePicture", AppImages.placeholder),
                fullName: get(userDetails, "fullName", "")
            });
        }

        return comments;
    };

    useEffect(() => {
        if (listOfPosts.length !== 0 && isProcessing) {
            handleUserDetails();
        }
    }, [listOfPosts, isProcessing]);

    // Handle modals for likes, comments, and reports
    const handleLikesClick = (postId) => {
        const post = filteredPosts.find(post => post.postId === postId);
        if (post) {
            setCurrentPostId(postId);
            setCurrentLikesArray(post.processedLikes || []);
            setShowLikes(true);
        }
    };

    const handleCommentsClick = (postId) => {
        const post = filteredPosts.find(post => post.postId === postId);
        if (post) {
            setCurrentPostId(postId);
            setCurrentCommentsArray(post.processedComments || []);
            setShowComments(true);
        }
    };

    const handleReportedUsersClick = (postId) => {
        const post = filteredPosts.find(post => post.postId === postId);
        if (post) {
            setCurrentPostId(postId);
            setCurrentReportedUsers(post.reportedUserIds || []);
            setShowReportedUsers(true);
        }
    };

    const handlePostDeleteClick = (postId) => {
        setCurrentPostId(postId);
        setShowPostDelete(true);
    };

    const handleRemovePost = async () => {
        try {
            await FBServices.deletePost(currentPostId);
            setShowPostDelete(false);
            // Remove post from list
            setFilteredPosts(filteredPosts.filter(post => post.postId !== currentPostId));
        } catch (error) {
            AppLogger("error removing product", error);
        }
    };

    const updatePost = async (commentsArray) => {
        setDeleteModal({ ...deleteModal, show: false });
        try {
            await FBServices.updatePostFirebase(currentPostId, { comments: commentsArray });

            // Update the comments in the filtered posts
            const updatedPosts = filteredPosts.map(post => {
                if (post.postId === currentPostId) {
                    return {
                        ...post,
                        comments: commentsArray,
                        processedComments: commentsArray
                    };
                }
                return post;
            });

            setFilteredPosts(updatedPosts);
            setCurrentCommentsArray(commentsArray);
        } catch (error) {
            AppLogger("error updating post", error);
        }
    };

    const handleDeleteComment = () => {
        const commentIndex = deleteModal.commentIndex;
        const updatedComments = currentCommentsArray.filter((_, i) => i !== commentIndex);
        updatePost(updatedComments);
    };

    const handleDeleteReply = () => {
        const commentIndex = deleteModal.commentIndex;
        const replyIndex = deleteModal.replyIndex;

        const updatedComments = [...currentCommentsArray];
        const updatedReplies = updatedComments[commentIndex].replies.filter((_, i) => i !== replyIndex);
        updatedComments[commentIndex].replies = updatedReplies;

        updatePost(updatedComments);
    };

    // Process message to render hashtags as chips inline with text
    const processMessageWithHashtags = (message) => {
        if (!message) return null;

        const parts = [];
        const words = message.split(' ');

        words.forEach((word, index) => {
            if (word.startsWith('#')) {
                parts.push(
                    <span key={index} style={{ marginRight: '4px' }}>
                        <Chip
                            size="small"
                            icon={<TagIcon style={{ fontSize: '12px' }} />}
                            label={word.substring(1)}
                            sx={{
                                backgroundColor: '#2196F3',
                                color: 'white',
                                fontSize: '0.75rem',
                                height: '24px',
                                display: 'inline-flex',
                                '& .MuiChip-icon': {
                                    color: 'white',
                                    marginRight: '-4px'
                                }
                            }}
                        />
                    </span>
                );
            } else {
                parts.push(<span key={index}>{word} </span>);
            }
        });

        return <span>{parts}</span>;
    };

    // Enhanced post item component with likes and comments functionality
    const EnhancedPostItem = ({ item, name, message, profilePhoto, messageTime }) => {
        return (
            <Paper elevation={3} sx={{
                borderRadius: '12px',
                overflow: 'hidden',
                boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                marginBottom: '24px',
                backgroundColor: 'white',
                '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)',
                }
            }}>
                <CardHeader
                    avatar={
                        <Avatar
                            src={profilePhoto}
                            alt={name}
                            sx={{ width: 50, height: 50, border: '2px solid #f0f0f0' }}
                        />
                    }
                    title={<Typography variant="h6">{name}</Typography>}
                    subheader={messageTime}
                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={() => handlePostDeleteClick(item.postId)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    }
                />
                <Divider />
                <Box sx={{ p: 3 }}>
                    <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.6 }}>
                        {processMessageWithHashtags(message)}
                    </Typography>

                    {item.media && item.media.length > 0 ? (
                        <Box sx={{
                            width: '100%',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            mb: 2
                        }}>
                            {item.media.map((mediaItem, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        mb: mediaItem.mediaType && mediaItem.mediaType.toLowerCase() === 'video' ? 2 : 0,
                                        cursor: 'pointer',
                                        position: 'relative'
                                    }}
                                    onClick={() => {
                                        setSelectedPost(item);
                                        setShowGallery(true);
                                        setSelectedGalleryItem(index);
                                    }}
                                >
                                    {mediaItem.mediaType && mediaItem.mediaType.toLowerCase() === 'video' ? (
                                        <video
                                            src={mediaItem.mediaURL}
                                            poster={mediaItem.mediaThumbnail || AppImages.placeholder}
                                            controls
                                            onError={(e) => {
                                                console.error("Video loading error:", e);
                                                e.target.onerror = null;
                                                e.target.poster = AppImages.placeholder;
                                            }}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '8px',
                                                maxHeight: '400px'
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src={mediaItem.mediaURL || AppImages.placeholder}
                                            alt="Post"
                                            onError={(e) => {
                                                console.error("Image loading error:", e);
                                                e.target.onerror = null;
                                                e.target.src = AppImages.placeholder;
                                            }}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                                maxHeight: '400px'
                                            }}
                                        />
                                    )}
                                </Box>
                            ))}
                        </Box>
                    ) : null}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 2,
                        pt: 2,
                        borderTop: '1px solid #f0f0f0'
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Button
                                startIcon={<FavoriteIcon />}
                                onClick={() => handleLikesClick(item.postId)}
                                sx={{ color: '#2196F3' }}
                            >
                                {(item.likes ? item.likes.length : 0) || 0} Likes
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Button
                                startIcon={<CommentIcon />}
                                onClick={() => handleCommentsClick(item.postId)}
                                sx={{ color: '#2196F3' }}
                            >
                                {(item.comments ? item.comments.length : 0) || 0} Comments
                            </Button>
                        </Box>
                        {item.reportedUserIds && item.reportedUserIds.length > 0 && (
                            <Button
                                variant="outlined"
                                color="warning"
                                size="small"
                                onClick={() => handleReportedUsersClick(item.postId)}
                            >
                                {item.reportedUserIds.length} Reports
                            </Button>
                        )}
                    </Box>
                </Box>
            </Paper>
        );
    };

    return (
        <>
            <div className='side-wrp'>
                <Sidebar />
            </div>

            {/* Content Area */}
            <div className="content-area" style={{ marginLeft: '400px', padding: '20px', backgroundColor: '#f5f5f5' }}>
                {selectedUserId && (
                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setSelectedUserId(null);
                                // Re-run the user details process with all posts
                                handleUserDetails();
                            }}
                        >
                            Clear User Filter
                        </Button>
                    </Box>
                )}
                <Container maxWidth="lg" style={styles.container}>
                    <Link
                        to={AppRoutes.home}
                        className="back-button"
                    >
                        <ArrowBackIcon sx={{ mr: 1 }} /> Back to Home
                    </Link>

                    {/* Social Feed Header */}
                    <Paper sx={{
                        borderRadius: '8px',
                        overflow: 'hidden',
                        mb: 3
                    }}>
                        <Box sx={{
                            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                            color: 'white',
                            p: 4,
                            textAlign: 'center'
                        }}>
                            <Typography variant="h4" fontWeight="bold" gutterBottom>
                                Featured Hashtag Feed
                            </Typography>
                            <Chip
                                icon={<TagIcon />}
                                label="outdoortrader"
                                sx={{
                                    bgcolor: 'rgba(255, 255, 255, 0.2)',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '1.1rem',
                                    px: 1,
                                    py: 2,
                                    '& .MuiChip-icon': {
                                        color: 'white'
                                    }
                                }}
                            />
                        </Box>
                    </Paper>

                    {(loading || isProcessing) ? (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '300px',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                        }}>
                            <CircularProgress color="primary" size={40} />
                            <Typography variant="h6" sx={{ ml: 2 }}>
                                Loading posts...
                            </Typography>
                        </Box>
                    ) : filteredPosts.length > 0 ? (
                        filteredPosts.map((item) => (
                            <EnhancedPostItem
                                key={get(item, "postId", "")}
                                item={item}
                                name={get(item, "fullName", "")}
                                message={get(item, "caption", "")}
                                profilePhoto={get(item, "profilePicture", AppImages.placeholder)}
                                messageTime={handleDateTime(get(item, "createdAt", ""), DateFormats.dateFormatString)}
                            />
                        ))
                    ) : (
                        <Box sx={{
                            textAlign: 'center',
                            padding: '60px 0',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                        }}>
                            <TagIcon sx={{ fontSize: 60, color: '#cccccc', mb: 2 }} />
                            <Typography variant="h5" gutterBottom>
                                No Posts Found
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                No posts with {hashtagName} hashtag were found.
                            </Typography>
                        </Box>
                    )}
                </Container>
            </div>

            {/* Gallery Modal */}
            <GalleryModal
                show={showGallery}
                setShow={setShowGallery}
                mediaArray={selectedPost ? get(selectedPost, "media", []) : []}
                selectedItem={selectedGalleryItem}
            />

            {/* Likes Modal */}
            <LikesModal
                show={showLikes}
                setShow={setShowLikes}
                likesList={currentLikesArray}
                title={"All Likes"}
            />

            {/* Comments Modal */}
            <CommentsModal
                show={showComments}
                setShow={setShowComments}
                commentsList={currentCommentsArray}
                setCommentsArray={(list) => setCurrentCommentsArray(list)}
                title={"All Comments"}
                deleteCommentFunc={(commentIndex) => {
                    setDeleteModal({
                        type: "Comment",
                        commentIndex: commentIndex,
                        replyIndex: 0,
                        show: true,
                    });
                }}
                deleteReplyFunc={(comIndex, repIndex) => {
                    setDeleteModal({
                        type: "Reply",
                        commentIndex: comIndex,
                        replyIndex: repIndex,
                        show: true
                    });
                }}
            />

            {/* Reported Users Modal */}
            <ReportedUsersModal
                show={showReportedUsers}
                setShow={setShowReportedUsers}
                reportedUsersList={currentReportedUsers}
                setReportedUsersList={(list) => setCurrentReportedUsers(list)}
                title={"Reported Post Contributors"}
                AllUsers={AllUsers}
            />

            {/* Delete Post Confirmation Modal */}
            <CustomModal
                show={showPostDelete}
                setShow={(val) => setShowPostDelete(val)}
                title={`Remove Post`}
                desc={`Are you sure you want to remove this post?`}
                btnText={`Yes`}
                onClickDone={() => handleRemovePost()}
            />

            {/* Delete Comment/Reply Modal */}
            <DeleteCommentModal
                show={deleteModal.show}
                setShow={(val) => setDeleteModal({ ...deleteModal, show: val })}
                title={`Remove ${deleteModal.type}`}
                desc={`Are you sure you want to remove this ${deleteModal.type}?`}
                btnText={`Yes`}
                onClickDone={() => deleteModal.type == "Comment" ? handleDeleteComment() : handleDeleteReply()}
                centered={true}
            />
        </>
    );
}

export default ListOfHashtagPosts